var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-tree-container"},[_c('div',{staticClass:"header d-flex d-md-none flex-column"},[_c('v-row',{staticClass:"ma-2",attrs:{"no-gutters":"","justify":"space-between"}},[_c('h2',{staticClass:"category-tree-title"},[_vm._v("I NOSTRI PRODOTTI")]),_c('v-btn',{staticClass:"white pa-0",attrs:{"depressed":"","icon":"","aria-label":_vm.$t('common.close')},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$close")])],1)],1)],1),_c('v-list',{staticClass:"category-tree px-2 px-sm-0"},[(_vm.navbarItems && _vm.navbarItems.proposals)?_vm._l((_vm.navbarItems.proposals),function(proposal){return _c('v-list-item',{key:proposal.proposalId,staticClass:"navbar-item d-flex",attrs:{"link":"","to":{
          name: 'Category',
          params: { pathMatch: proposal.slug }
        }}},[_c('v-list-item-avatar',{staticClass:"mr-2",attrs:{"size":"28"}},[_c('img',{attrs:{"width":"28","height":"28","src":proposal.img,"alt":proposal.imgAlt,"title":proposal.imgDescription}})]),_c('v-list-item-title',[_vm._v(" "+_vm._s(proposal.descr)+" ")])],1)}):_vm._e(),_vm._l((_vm.categories),function(category){return _c('v-list-group',{key:category.categoryId,staticClass:"category1",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('img',{staticClass:"mr-2",attrs:{"width":"16","src":_vm.$ebsn.meta(category, 'category_info.ICON')}}),_c('v-list-item-title',[_c('router-link',{attrs:{"aria-label":`${category.slug}`,"title":`${category.slug}`,"to":{
              name: 'Category',
              params: { pathMatch: category.slug }
            },"event":""},nativeOn:{"click":function($event){$event.preventDefault();return _vm.nop.apply(null, arguments)}}},[_vm._v(" "+_vm._s(category.name)+" ")])],1)]},proxy:true}],null,true)},[_vm._l((category.categories),function(category2){return [_c('v-list-item',{key:category2.categoryId,staticClass:"category2",attrs:{"link":"","to":{
            name: 'Category',
            params: { pathMatch: category2.slug }
          }}},[_c('v-list-item-title',[_vm._v(_vm._s(category2.name))])],1)]})],2)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }