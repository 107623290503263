<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col justify="center">
        <v-card
          class="d-flex align-center mb-6 justify-center"
          color="grey lighten-2"
          flat
          height="100"
          tile
        >
          <v-card class="pa-2" flat tile>
            <v-list-item-content>
              <v-list-item-title
                class="text-center overline mb-1 font-weight-semibold"
              >
                {{ getVersionTile }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-center"
                ><strong class="primary--text">{{
                  getVersion
                }}</strong></v-list-item-subtitle
              >
            </v-list-item-content>
            <v-btn
              @click="reloadNewVersion"
              class="reload-button"
              large
              outlined
              width="100%"
              depressed
            >
              {{ $t("common.upgradeVersion") }}
            </v-btn>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "UpgradeVersion",
  props: ["version"],
  data() {
    return {
      loading: null
    };
  },
  computed: {
    getVersionTile() {
      return this.version &&
        this.version.lastVersion &&
        this.version.lastVersion.description
        ? this.version.lastVersion.description
        : this.$t("common.versionTitle");
    },
    getVersion() {
      return this.version.lastVersion.version;
    }
  },
  methods: {
    ...mapActions({}),
    reloadNewVersion() {
      this.$emit("submit", true);
    }
  }
};
</script>
