<template>
  <v-card class="cart-summary text-right" tile flat v-if="cart.cartId > 0">
    <v-card-actions class="justify-space-around">
      <v-row no-gutters>
        <v-col cols="6" class="gross-infos-col d-flex px-1" align="start">
          <!-- <div v-if="cart.totalPriceVariable > 0" class="gross-infos-row pa-0">
            <span class="gross-infos">{{
              $t("cartSummary.totalPriceVariable")
            }}</span>
            <span class="value gross-infos">{{
              $n(cart.totalPriceVariable, "currency")
            }}</span>
          </div> -->
          <!-- <div v-if="cart.refundTotal > 0" class="gross-infos-row pa-0">
            <span class="gross-infos">{{ $t("cartSummary.refundTotal") }}</span>
            <span class="gross-infos value">{{
              $n(cart.refundTotal, "currency")
            }}</span>
          </div>
          <div
            v-if="
              cart.deliveryFee >= 0 &&
                (cart.shippingAddress.addressType == 'home' ||
                  cart.shippingAddress.addressType == 'onboard')
            "
            class="gross-infos-row pa-0"
          >
            <span class="gross-infos">{{ $t("cartSummary.deliveryFee") }}</span>
            <span class="gross-infos value">{{
              $n(cart.deliveryFee, "currency")
            }}</span>
          </div> -->
        </v-col>
        <v-col cols="6" class="gross-total-col px-1" align="end">
          <!-- <div v-if="cart.giftTotal > 0" class="advantages pa-0">
            <span class="advantages-text font-weight-semibold">
              {{ $t("cartSummary.giftTotal") }}
            </span>
            <strong class="advantages-value font-weight-semibold value">
              -{{ $n(cart.giftTotal, "currency") }}
            </strong>
          </div> -->
          <div class="gross-total d-flex justify-space-between">
            <div class="gross-total-text">
              {{ $t("cartSummary.grossTotal") }}
            </div>
            <div class="gross-total-value value">
              {{ $n(cart.totalPrice, "currency") }}
            </div>
          </div>
          <div
            v-if="cart.totalPriceVariable > 0"
            class="gross-infos-row pa-0 d-flex justify-space-between"
          >
            <span class="font-weight-semibold gross-infos">{{
              $t("cartSummary.totalPriceVariable")
            }}</span>
            <span class="font-weight-semibold value gross-infos">{{
              $n(cart.totalPriceVariable, "currency")
            }}</span>
          </div>
        </v-col>
        <v-col cols="6" class="px-1 mt-2" v-if="cart.totalItems > 0">
          <v-btn
            @click="handleContinueShopping"
            class="rounded-pill btn-continue"
            large
            outlined
            block
            depressed
            >{{ $t("cart.button.continue") }}</v-btn
          >
        </v-col>
        <v-col cols="6" class="px-1 mt-2" v-if="cart.totalItems > 0">
          <v-btn
            :to="{ name: 'Checkout' }"
            class="rounded-pill"
            color="primary"
            large
            block
            depressed
            @click="closeDrawer"
            >{{ $t("cart.button.goToPayment") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
  <v-card v-else>{{ $t("no-cart") }}</v-card>
</template>
<style lang="scss">
.cart-summary {
  .btn-continue {
    background-color: #ffffff;
  }
  display: grid;
  .gross-total {
    font-size: 1.78rem;
    line-height: 2rem;
    text-transform: uppercase;
    font-weight: 600 !important;
    margin-top: 0;
  }
  .advantages {
    color: var(--v-white-base);
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
  }
  .gross-total-col,
  .gross-infos-col {
    height: 70px;
  }
  .gross-infos-col {
    flex-direction: column;
    gap: 6px;
    justify-content: space-evenly;
    .gross-infos-row {
      display: flex;
      justify-content: space-between;
    }
    .gross-infos {
      // display: block;
      font-size: 14px;
      line-height: 16px;
      text-transform: none;
      // font-weight: bold;
      margin-top: 0;
    }
  }
  .value {
    min-width: 100px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-card__actions {
      padding: 8px 0;
    }
  }
}
</style>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "CartSummary",
  props: ["full"],
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart,
      storeDrawerRight: state => state.app.drawerRight
    }),
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    }
  },
  methods: {
    ...mapActions({
      emptyCart: "cart/emptyCart",
      updatedDrawerRight: "app/updatedDrawerRight",
      removeGiftCode: "cart/removeGiftCode"
    }),
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
        }
      }
    },
    handleContinueShopping() {
      this.updatedDrawerRight(false);
      if (this.$route.name == "Checkout") {
        this.$router.push({ name: "Home" });
      }
    },
    closeDrawer() {
      this.updatedDrawerRight(false);
    },
    removeCode(giftCodeId) {
      this.removeGiftCode(giftCodeId);
    },
    changeService() {},
    changeTimeslot() {},
    changeDelivery() {}
  }
};
</script>
