<template>
  <v-container>
    <v-row lass="justify-center align-center">
      <v-col cols="12">
        <h3 class="font-weight-semibold text-center">
          {{ $t("footer.faqFooter.title") }}
        </h3>
      </v-col>
    </v-row>
    <v-row
      class="justify-center align-center pb-10"
      v-if="links && links.length > 0"
    >
      <v-col
        cols="12"
        md="auto"
        v-for="item in links"
        :key="item.templateNavItemId"
      >
        <v-btn
          large
          depressed
          elevation="0"
          outlined
          class="px-6 rounded-pill w-100"
          :href="item.itemUrl"
          @click="clicked"
          ><v-icon color="primary" class="mr-2"
            >{{ `$${item.iconSource}` }} </v-icon
          >{{ item.name }}</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped lang="scss"></style>

<script>
import clickHandler from "@/commons/mixins/clickHandler";

export default {
  name: "PreFooter",
  mixins: [clickHandler],
  props: {
    links: { type: Array, required: true }
  },
  methods: {
    linkName(child) {
      return child?.name.length > 1 ? child.name : "Vai al sito";
    },
    onLinkClick(e) {
      var url = e.currentTarget.href;
      let fileName = "";
      this.$emit("close");
      this.$nextTick(() => {
        if (
          this.$platform_is_cordova &&
          e.currentTarget.href.endsWith(".pdf")
        ) {
          fileName = url.substring(url.lastIndexOf("/") + 1, url.length);
          fileName = fileName.substring(0, fileName.length - 4);
          this.handleReceiptDownload(url, fileName);
        } else if (e.target.className.includes("cookie-widget-trigger")) {
          if (window.Cookiebot) {
            window.Cookiebot.show();
          }
        } else {
          this.clicked(e);
        }
      });
    },
    goToExternalPage(url) {
      if (this.$platform_is_cordova && url.indexOf("http") > -1) {
        //if app open on _system browser
        window.cordova.InAppBrowser.open(url, "_system");
      } else {
        window.open(url, "_blank");
      }
    }
  }
};
</script>
